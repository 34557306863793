import React from "react";
import "./Explore.style.css";

const About = () => {
  return (
    <div className="explore-container">
      
    </div>
  );
};

export default About;
