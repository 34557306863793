import React from "react";
import "./Home.css"; // Assicurati che il nome del file sia corretto
// import skyscannerLogo from "../../assets/skyscanner-logo.png";
// import bookingLogo from "../../assets/Booking.com_logo.png";
// import ticketoneLogo from "../../assets/TicketOne_logo.png";
import FestivalCard from "../../components/festival-card/festival-card";
// import festivalsData from "../../utils/festival.json"; // Assicurati che il percorso sia corretto
import Search from "../../components/search/search";
import festivals from "../../assets/utils/festivals.json";
// import axios from 'axios';

// Da rivedere
// import ExpediaBanner from "../../components/expedia-banner/expedia-banner";

const Home = () => {
  // const [isButtonVisible, setIsButtonVisible] = useState(true);
  // const [showForm, setShowForm] = useState(false);
  // const [formData, setFormData] = useState({
  //   name: "",
  //   email: "",
  //   birthDate: Date,
  // });

  // console.log("Festival Data",festivalsData);
  // const handleButtonClick = () => {
  //   setIsButtonVisible(false);
  //   setShowForm(true);
  // };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const actualUrl = `${window.location.origin}/graphql`;
  //   try {
  //     const response = await axios.post(actualUrl, {
  //       query: `
  //         mutation AddFormData($name: String!, $email: String!, $birthDate: Date!) {
  //           addFormData(name: $name, email: $email, birthDate: $birthDate) {
  //             id
  //             name
  //             email
  //             birthDate
  //           }
  //         }
  //       `,
  //       variables: {
  //         name: formData.name,
  //         email: formData.email,
  //         birthDate: new Date(formData.birthDate).toISOString(),
  //       },
  //     });

  //     const result = response.data;
  //     console.log(result);

  //     if (result.errors) {
  //       console.error("Errore:", result);
  //       alert(`Errore nell'invio del form: ${result.errors[0].message}`);
  //     } else {
  //       console.log("Form inviato:", result.data.addFormData);
  //       alert("Form inviato con successo!");
  //     }
  //   } catch (error) {
  //     console.error("Errore:", error);
  //     alert(`Errore nell'invio del form: ${error.message}`);
  //   }
  // };

  return (
    <>
      <h1 className="home-title">
        Vivi l'esperienza musicale dei tuoi sogni su misura per te!
      </h1>
      <div className="home-container">
        <div className="centered-text">
        <h3 className="home-subtitle">
            Sei appassionat* di musica e sogni di vivere i festival più
            spettacolari? La nostra piattaforma è nata per questo. Che si tratti
            di un festival indie, un mega raduno elettronico sotto le stelle, o
            un evento jazz in una città vibrante, noi ti portiamo lì.
          </h3>
          <p className="home-text-2">Cerca festival</p>
          
          <div className="festival-card-container">
            <Search />
          </div>
          
          <p className="home-text-2">IN ARRIVO</p>
          {/* <p>Dai un'occhiata ai festival in arrivo</p> */}
          <div className="festival-card-container">
            {festivals.map(
              (festival, index) =>
                index < 4 && <FestivalCard key={index} festival={festival} />
            )}
          </div>

          {/* <a
        href="https://expedia.com/affiliates/flights-search-nap-to-bcn.kVcHiZr"
        className="flight-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        Vai a Expedia
      </a>
         */}
          <p className="home-text-2">
            Non è solo un viaggio, è la colonna sonora della tua vita.
          </p>
          {/* <div>
            <div className="partner-logo-wrapper">
              <img
                src={skyscannerLogo}
                alt="Skyscanner Logo"
                className="partner-logo"
              />
              <img
                src={bookingLogo}
                alt="Skyscanner Logo"
                className="partner-logo"
              />
              <img
                src={ticketoneLogo}
                alt="Skyscanner Logo"
                className="partner-logo"
              />
            </div>
          </div> */}
          {/* <p className="home-text-1">
          Esplora un mondo di eventi musicali imperdibili. Consulta i migliori
          festival, scopri nuove destinazioni e organizza il tuo viaggio.
        </p> */}
          
        </div>
        {/* <ExpediaBanner /> */}
      </div>
    </>
  );
};

export default Home;
