import React from "react";
import "./train-search.style.css";
import trainIcon from "../../assets/train-ticket-icon.png";

const generateTripUrl = (departure, arrival, date, returnDate) => {
  const params = new URLSearchParams({
    departurecitycode: departure.code,
    departurecity: departure.name,
    arrivalcitycode: arrival.code,
    arrivalcity: arrival.name,
    departdate: date,
    departhouript: "00",
    departminuteipt: "00",
    returndate: returnDate, // Aggiunto
    returnhouript: "00", // Aggiunto
    returnminuteipt: "00", // Aggiunto
    hidadultnum: "1",
    hidinfantnum: "0", // Aggiunto
    hidoldnum: "0",
    railcards: "{}",
    isregularlink: "1",
    biztype: "IT", // Aggiunto
  });

  return `https://trip.com/trains/list?${params.toString()}`;
};

const TrainSearch = ({ departure, arrival, date, returnDate }) => {
  return (
    <div className="train-info" key={departure.stationName}>
      <img src={trainIcon} alt="train-icon" className="train-icon" />
      <div className="train-info-wrapper">
        
          <span style={{ textTransform: "uppercase" }}>
            {departure.stationName}
            
          </span>
          <span style={{ textTransform: "uppercase" }}>
          {arrival.stationName}
          </span>
            
        
      </div>

      <a
        className="train-search"
        href={generateTripUrl(departure, arrival, date, returnDate)}
        target="_blank"
        rel="sponsored noopener noreferrer"
      >
        <span>Prenota <small style={{ color: "white" }}>su Trip</small></span>
      </a>
    </div>
  );
};

export default TrainSearch;
