import React, { useState, useRef, useEffect } from "react";
import citiesMap from "../../assets/utils/cities.json";
import "./travel-search.style.css";
import TrainSearch from "../trains-search/train-search";
import { addDays, format, parse } from "date-fns";
import { it } from "date-fns/locale";
import flightIcon from "../../assets/air-ticket-icon.png";
import ticketIcon from "../../assets/ticket-icon.png";
import ExpediaHotels from "../expedia-url/expedia";
import BookingHotels from "../booking/booking-hotels";
import OmioTravel from "../omio-travel/omioTravel";

const cities = Object.values(citiesMap.countries.IT.cities).flatMap((city) => ({
  name: city.name,
  region: city.region,
  airports: city.airports,
  stations: city.stations,
}));
const apiKey = "PVE2h9APwvCrttCNfJO3Gr6oCgAQPpeo"; // Sostituisci con la tua API Key
const apiSecret = "VSfKyPTQq06nb2T5";

const TravelSearch = ({ festival }) => {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [token, setToken] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);
  const inputRef = useRef();
  const [selectedCity, setSelectedCity] = useState(null);
  const [error, setError] = useState(null);
  const [flightOffers, setFlightOffers] = useState([]);
  const festivalCity = cities.find((city) => {
    return city?.name === festival?.city;
  });
  const [selectedTicket, setSelectedTicket] = useState();
  const tickets = festival?.tickets;

  const handleTicket = (index) => {
    setSelectedTicket(tickets[index]);
    console.log(tickets[index], "Selected ticket");

    if (selectedCity) fetchFlightOffers(selectedCity);
  };
  const generateTripFlightUrl = (date, returnDate) => {
    const params = new URLSearchParams({
      ddate: date, // es: '2024-11-08'
      dcity:
        selectedCity.airports.length > 1
          ? selectedCity?.name.toLowerCase().substring(0, 3)
          : selectedCity.airports[0].iata_code, // es: 'ROM'
      dairport: selectedCity.airports[0].iata_code, // es: 'FCO'
      acity:
        festivalCity.airports.length > 1
          ? festivalCity?.name.toLowerCase().substring(0, 3)
          : festivalCity.airports[0].iata_code, // es: 'MIL'
      aairport: festivalCity.airports[0].iata_code, // es: 'LIN'
      adate: returnDate, // es: '2024-11-11'
      triptype: "1", // round trip
      classtype: "0", // economy
      classgroupsearch: "true",
      adult: "1",
      child: "0",
      infant: "0",
      stoptype: "0",
    });
    return `https://trip.com/m/flights/showfarefirst?${params.toString()}`;
  };
  async function getAccessToken() {
    const response = await fetch(
      "https://test.api.amadeus.com/v1/security/oauth2/token",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          grant_type: "client_credentials",
          client_id: apiKey,
          client_secret: apiSecret,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok " + response.statusText);
    }

    const data = await response.json();
    return data.access_token;
  }

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const token = await getAccessToken();
        setToken(token);
        console.log("[Amadeus] Token acquired");
      } catch (error) {
        console.error("Error fetching access token:", error);
      }
    };

    fetchToken();
  }, []);

  const fetchFlightOffers = async (city) => {
    if (!token) return;

    setLoading(true);
    try {
      const response = await fetch(
        `https://test.api.amadeus.com/v2/shopping/flight-offers?originLocationCode=${
          city?.airports[0]?.iata_code
        }&destinationLocationCode=${
          festivalCity?.airports[0]?.iata_code
        }&departureDate=${format(
          parse(selectedTicket?.startDate, "dd/MM/yyyy", new Date()),
          "yyyy-MM-dd"
        )}&returnDate=${format(
          addDays(parse(selectedTicket?.endDate, "dd/MM/yyyy", new Date()), 1),
          "yyyy-MM-dd"
        )}&adults=1&max=250`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        setError(response);
        setFlightOffers([]);
        setLoading(false);
        console.log(error);

        throw new Error("Network response was not ok " + response.statusText);
      }

      const data = await response.json();
      setFlightOffers(data);
      setLoading(false);
      //   setCarriers(data?.dictionaries?.carriers); // Imposta le compagnie aeree
    } catch (error) {
      console.error("Error fetching flight offers:", error);
    }
  };

  useEffect(() => {
    const handleQuery = (query) => {
      const results = cities.filter((city) =>
        city.name.toLowerCase().startsWith(query?.toLowerCase())
      );
      query.length > 1 ? setFilteredCities(results) : setFilteredCities([]);
      selectedCity?.name !== query && setSelectedCity(null);
      setFlightOffers([]);
    };
    handleQuery(query);
    console.log(selectedCity, "Selected city");
  }, [query, selectedCity]);

  const formatStartDate = (date) => {
    const parsedStartDate = parse(date, "dd/MM/yyyy", new Date());
    const formattedStartdDate = format(parsedStartDate, "d MMMM yyyy", {
      locale: it,
    });
    return formattedStartdDate;
  };

  const formatEndDate = (date) => {
    const parsedEndDate = parse(date, "dd/MM/yyyy", new Date());
    const formattedEnddDate = format(parsedEndDate, "d MMMM yyyy", {
      locale: it,
    });
    return formattedEnddDate;
  };

  return (
    <>
      {tickets && (
        <span style={{ marginBottom: "0.5rem" }}>Seleziona la data</span>
      )}

      {tickets && tickets.length > 0 && (
        <div className="travel-results">
          {tickets.map((ticket, index) => (
            <div
              className={`ticket ${
                ticket.minPrice === selectedTicket?.minPrice ? "selected" : ""
              }`}
              key={index}
              onClick={() => {
                if (selectedTicket?.minPrice === ticket?.minPrice) return;
                setSelectedTicket(ticket);
                handleTicket(index);
                console.log(ticket, "Selected ticket");
              }}
            >
              <span>
                {ticket?.startDate !== ticket?.endDate
                  ? ` Dal ${formatStartDate(
                      ticket?.startDate
                    )} al ${formatEndDate(ticket?.endDate)}`
                  : `${formatStartDate(ticket?.startDate)}`}
              </span>
              <span>
                {ticket?.lineup === "TBA"
                  ? "TBA"
                  : `${ticket?.lineup?.join(", ")} e molti altri...`}
              </span>

              {ticket?.minPrice === selectedTicket?.minPrice && (
                <a
                  className="ticket-link"
                  href={ticket?.ticketLink}
                  target="_blank"
                  rel="sponsored noopener noreferrer"
                >
                  <img
                    src={ticketIcon}
                    alt="ticket-icon"
                    className="flight-icon"
                  />
                  <span className="buy-ticket">
                    <span style={{ color: "#FFA666" }}>Acquista</span> su{" "}
                    {ticket?.vendor}
                  </span>
                </a>
              )}
            </div>
          ))}
        </div>
      )}

      {selectedTicket && (
        <>
          <span style={{ marginBottom: "0.5rem" }}>
            Inserisci la città di partenza
          </span>
          <input
            className="input-placeholder"
            placeholder="Città di partenza"
            onChange={(e) => setQuery(e.target.value)}
            value={query}
            ref={inputRef}
          />
        </>
      )}

      {filteredCities.length > 0 && (
        <div className="travel-results">
          {filteredCities.map((city, index) => (
            <div
              className={`travel-departure ${
                city.name === query && selectedCity?.name === city.name
                  ? "selected"
                  : ""
              }`}
              key={index}
              onClick={() => {
                if (selectedCity?.name === city.name) return;
                setSelectedCity(city);
                setQuery(city?.name);
                if (city?.airports?.length > 0) fetchFlightOffers(city);
              }}
            >
              {city?.name} ({city?.region})
            </div>
          ))}
        </div>
      )}
      {loading && <span>Carico le soluzioni di viaggio</span>}
      {selectedCity && !loading && query === selectedCity?.name && (
        <span>Opzioni di viaggio</span>
      )}
      <div className="trip-options">
        {selectedCity && !loading && query === selectedCity?.name && (
          <div className="trip-date-container">
            <span className="trip-date">{selectedTicket?.startDate}</span>
            <div className="trip-date-divider"></div>
            <span className="trip-date">
              {format(
                addDays(
                  parse(selectedTicket?.endDate, "dd/MM/yyyy", new Date()),
                  1
                ),
                "dd/MM/yyyy"
              )}
            </span>
          </div>
        )}
        {selectedCity &&
          !loading &&
          query === selectedCity?.name &&
          selectedCity?.region !== "Sicilia" && (
            <TrainSearch
              departure={{
                name: selectedCity?.name,
                code: selectedCity?.stations[0]?.code,
                stationName: selectedCity?.stations[0]?.name,
              }}
              arrival={{
                code: festivalCity?.stations[0]?.code,
                name: festivalCity?.name,
                stationName: festivalCity?.stations[0]?.name,
              }}
              date={format(
                parse(selectedTicket?.startDate, "dd/MM/yyyy", new Date()),
                "yyyy-MM-dd"
              )}
              returnDate={format(
                addDays(
                  parse(selectedTicket?.endDate, "dd/MM/yyyy", new Date()),
                  1
                ),
                "yyyy-MM-dd"
              )}
            />
          )}

        {flightOffers &&
          !loading &&
          flightOffers?.data?.length > 0 &&
          selectedCity?.airports?.length > 0 && (
            <div className="offer">
              <img src={flightIcon} alt="flight-icon" className="flight-icon" />
              <div className="carriers-offer">
                <span className="airport">
                  {selectedCity?.airports[0]?.name}
                </span>
                <span className="airport">
                  {festivalCity?.airports[0]?.name}
                </span>
                {Object.entries(flightOffers?.dictionaries?.carriers)?.map(
                  ([code, name]) => {
                    const offersForCarrier = flightOffers?.data.filter(
                      (offer) => offer.validatingAirlineCodes[0].includes(code)
                    );
                    const lowestPriceForCarrier =
                      offersForCarrier.length > 0
                        ? offersForCarrier.reduce((lowest, offer) => {
                            const offerPrice = parseFloat(
                              offer.price.grandTotal
                            );
                            return offerPrice < lowest ? offerPrice : lowest;
                          }, parseFloat(offersForCarrier[0].price.grandTotal))
                        : null;

                    return (
                      offersForCarrier &&
                      offersForCarrier.length > 0 &&
                      selectedCity?.airports?.length > 0 && (
                        <span
                          className="carrier-name"
                          key={flightOffers.dictionaries.carriers[code]}
                        >
                          {name} <br />
                          <span>
                            {lowestPriceForCarrier &&
                              `${lowestPriceForCarrier} €`}
                          </span>
                        </span>
                      )
                    );
                  }
                )}
              </div>
              <a
                className="flight-search-button"
                target="_blank"
                rel="sponsored noopener noreferrer"
                href={generateTripFlightUrl(
                  format(
                    parse(selectedTicket?.startDate, "dd/MM/yyyy", new Date()),
                    "yyyy-MM-dd"
                  ),
                  format(
                    addDays(
                      parse(selectedTicket?.endDate, "dd/MM/yyyy", new Date()),
                      1
                    ),
                    "yyyy-MM-dd"
                  )
                )}
              >
                Prenota
                <small style={{ color: "white" }}> su Trip</small>
              </a>
            </div>
          )}
        {selectedTicket?.omioLink &&
          selectedCity &&
          flightOffers &&
          !loading &&
          selectedTicket?.omioLink?.length > 0 &&
          selectedTicket?.omioLink?.filter(
            (ticket) => selectedCity?.name === ticket?.from
          ).length > 0 && (
            <OmioTravel
              departure={selectedCity?.name}
              arrival={festivalCity?.name}
              hasAirport={selectedCity?.airports?.length > 0}
              link={
                selectedTicket?.omioLink?.filter(
                  (ticket) => selectedCity?.name === ticket?.from
                )[0]?.link
              }
            />
          )}
        {flightOffers?.data?.length === 0 && <div>Nessuna offerta trovata</div>}

        {selectedCity &&
          query === selectedCity?.name &&
          festival?.stay22Map &&
          !loading && (
            <>
              <span style={{ margin: "1rem 0" }}>Opzioni di alloggio</span>
              {selectedTicket.type === "bundle" && (
                <ExpediaHotels
                  link={selectedTicket?.expediaLink}
                  city={festival?.location}
                  checkIn={format(
                    parse(selectedTicket?.startDate, "dd/MM/yyyy", new Date()),
                    "yyyy-MM-dd"
                  )}
                  checkOut={format(
                    addDays(
                      parse(selectedTicket?.endDate, "dd/MM/yyyy", new Date()),
                      1
                    ),
                    "yyyy-MM-dd"
                  )}
                />
              )}

              <div className="stay22-map">
                <iframe
                  title="sta22-map"
                  id="stay22-widget"
                  width="100%"
                  height="428"
                  src={
                    selectedTicket?.stay22MapTicket?.length > 0
                      ? selectedTicket?.stay22MapTicket
                      : festival?.stay22Map
                  }
                  frameBorder="0"
                ></iframe>
              </div>
              <BookingHotels
                city={festivalCity?.name}
                checkIn={format(
                  parse(selectedTicket?.startDate, "dd/MM/yyyy", new Date()),
                  "yyyy-MM-dd"
                )}
                checkOut={format(
                  addDays(
                    parse(selectedTicket?.endDate, "dd/MM/yyyy", new Date()),
                    1
                  ),
                  "yyyy-MM-dd"
                )}
                link={selectedTicket?.bookingLink}
              />
            </>
          )}
      </div>
    </>
  );
};

export default TravelSearch;
