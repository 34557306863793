import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App'; // Importa il componente App
import ApolloProvider from './ApolloProvider'; // Importa ApolloProvider
import './assets/utils/fonts.css';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ApolloProvider>
    <App />
  </ApolloProvider>
);
