import React, { useEffect, useRef, useState } from "react";
import "./search.style.css";
import festivals from "../../assets/utils/festivals.json";
import FestivalCard from "../../components/festival-card/festival-card";
import AnimatedPlaceholder from "../animated-placeholder/animated-placeholder";

const Search = () => {
  const inputRef = useRef();
  const [results, setResults] = useState();
  const [query, setQuery] = useState("");

  useEffect(() => {
    const handleSearch = () => {
      if (query?.length < 2) {
        setResults([]);
      } else {
        const normalizedQuery = query.toLowerCase().trim();
        const queryWords = normalizedQuery.split(" ");
        const partialResults = festivals.filter((festival) => {
          const normalizedNome = festival.name.toLowerCase().trim();
          return queryWords.every((word) => normalizedNome.includes(word));
        });
        setResults(partialResults);
      }
    };
    handleSearch();
  }, [query]);
  const festivalsName = festivals.map((festival) => festival.name);
  return (
    <div className="wrapper">
      <AnimatedPlaceholder
        strings={festivalsName}
        setQuery={setQuery}
        query={query}
        inputRef={inputRef}
      />
      {results?.length>0 && query?.length >= 2 && (
        <span className="no-results">{`${results?.length} festival trovati`}</span>
      )}
      {results?.length===0 && query?.length >= 2 && (
        <span className="no-results">Nessun festival trovato</span>
      )}
      {results && query.length >= 2 && results?.length>0 && (
        <div className="results">
          {results?.map(
            (result, index) =>
              index < 10 && <FestivalCard key={index} festival={result} />
          )}
        </div>
      )}
    </div>
  );
};

export default Search;
