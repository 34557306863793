import React from 'react';
import './footer.style.css'; // Assicurati di creare un file CSS per gli stili del componente

const Footer = () => {
  return (
    <div className="footer">
      <p>&copy; {new Date().getFullYear()} SoundTrip</p>
    </div>
  );
};

export default Footer;