import React, { useState, useRef,useEffect } from 'react';
import './animated-placeholder.style.css';

const AnimatedPlaceholder = ({ strings, query, setQuery }) => {
  const [currentStringIndex, setCurrentStringIndex] = useState(0);
  const [currentPlaceholder, setCurrentPlaceholder] = useState('');
  const [charIndex, setCharIndex] = useState(0);
  const inputRef = useRef();

  useEffect(() => {
    if(query !== '') {return; }
    const interval = setInterval(() => {
      setCurrentPlaceholder((prev) => {
        const currentString = strings[currentStringIndex];
        if (charIndex < currentString.length) {
          setCharIndex(charIndex + 1);
          return prev + currentString[charIndex];
        } else {
          setCharIndex(0);
          setCurrentStringIndex((prevIndex) => (prevIndex + 1) % strings.length);
          return '';
        }
      });
    }, 100); // Cambia il valore per regolare la velocità dell'animazione

    return () => clearInterval(interval);
  }, [charIndex, currentStringIndex, strings, query]);

  return (
    <input
      className="input-placeholder"
      placeholder={currentPlaceholder}
      onChange={(e) => setQuery(e.target.value)}
      value={query}
      ref={inputRef}
    />
  );
};

export default AnimatedPlaceholder;