import React from "react";
import trainIcon from "../../assets/train-ticket-icon.png";
import flightIcon from "../../assets/air-ticket-icon.png";
import BusIcon from "../../assets/bus-ticket-icon.png";
const OmioTravel = ({ departure, arrival, link, hasAirport }) => {

  return (
    <div className="train-info" >
        <div className="icon-container">
        <img src={trainIcon} alt="train-icon" className="train-icon" />
      {hasAirport && <img src={flightIcon} alt="flight-icon" className="flight-icon" />}
      <img src={BusIcon} alt="flight-icon" className="flight-icon" />
        </div>
      
      <div className="train-info-wrapper">
        
          <span style={{ textTransform: "uppercase" }}>
            {departure}
            
          </span>
          <span style={{ textTransform: "uppercase" }}>
          {arrival}
          </span>
            
          
         
        
      </div>

      <a
        className="train-search"
        href={link}
        target="_blank"
        rel="sponsored noopener noreferrer"
      >
        <span>Prenota <small style={{ color: "white" }}>su Omio</small></span>
      </a>
    </div>
  );
};

export default OmioTravel;
