import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import festivals from "../../assets/utils/festivals.json";
import "./Festival.style.css";
import TravelSearch from "../../components/travel-search/travel-search";
import map from "../../assets/map.png";
import linkIcon from "../../assets/website-icon.png";

const Festival = () => {
  const { route } = useParams();
  const [festival, setFestival] = useState(null);
  const [open, setOpen] = useState(0);

  useEffect(() => {
    // Trova il festival con l'ID corrispondente
    const foundFestival = festivals.find(
      (festival) => festival?.name.replace(/\s+/g, "-").toLowerCase() === route
    );
    setFestival(foundFestival);
  }, [route]);

  return (
    <div className="festival-container">
      <h1 className="festival-name">{festival?.name}</h1>
      <p className="festival-info">{festival?.info}</p>

      {!festival?.tickets && <span>Coming soon</span>}
      {festival && <TravelSearch festival={festival} />}
      {festival?.playlistLink &&
      <div className="spotify-player-festival">
      <iframe title="spotify-playlist" style={{"border-radius":"10px", boxShadow: "1px 3px 3px rgba(0, 0, 0, 0.1)"}} src={festival?.playlistLink} width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
    </div>
      }
      <div className="menu">
        <div className="site-link">
          <img src={linkIcon} className="link-icon" alt="link"></img>
          <a
            rel="noreferrer"
            href={festival?.link}
            target="_top"
            className="link"
          >
            <span>Visita il sito del festival</span>
          </a>
        </div>
        {!open && (
          <div style={{ backgroundImage: `url(${map})` }} className="map">
            <div className="map-title">
              <span>{`${festival?.location.join(", ")}, ${
                festival?.city
              }`}</span>
            </div>
            <div className="open-map-button-container">
              <button className="open-map-button" onClick={() => setOpen(1)}>
                Apri su <span className="site-link-button">Google Maps</span>
              </button>
            </div>
          </div>
        )}

        {open === 1 && (
          <div
            className="map-iframe"
            style={{
              maxHeight: "350px",
              maxWidth: "500px",
              width: "100%",
              height: "100%",
            }}
          >
            <iframe
              title="map"
              width="100%"
              frameBorder={0}
              height="300px"
              zoom={14}
              referrerPolicy="no-referrer-when-downgrade"
              src={festival?.src}
              allowFullScreen
            ></iframe>
          </div>
        )}
      </div>
      
      
    </div>
  );
};

export default Festival;
