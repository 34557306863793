import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import Header from "./components/header/header"; // Assicurati che il percorso sia corretto
import Home from './pages/Home/Home'; // Assicurati che il percorso sia corretto
import About from './pages/About/About'; // Supponiamo che tu abbia un componente About
import './App.css';
import Festival from './pages/Festival/Festival';
import Footer from './components/footer/footer';
import Explore from './pages/Explore/Explore';

const App = () => {
  const [minimize, setMinimize] = useState(window.scrollY);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY < 400) {
        // Sostituisci 100 con il numero di pixel desiderato
        setMinimize(false);
      }
      if (window.scrollY > 400) {
        // Sostituisci 100 con il numero di pixel desiderato
        setMinimize(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [minimize]);
  return (
    <Router>
      <div className="App">
        <CookieConsent
          location="bottom"
          buttonText="Ho capito"
          cookieName="CookiesSoundTrip"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          Questo sito utilizza i cookie per migliorare l'esperienza utente.{" "}
          <a href="/privacy-policy" style={{ color: "#ffffff" }}>Scopri di più</a>
        </CookieConsent>
        <Header minimize={minimize} />
        
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/festival" element={<Explore />} />
          <Route path="/festival/:route" element={<Festival />} />
          {/* Aggiungi altre rotte qui */}
        </Routes>
      <Footer />
      </div>
    </Router>
  );
};

export default App;
